import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useGetSettingsQuery, useUpdateSettingsMutation } from '../../api/settingsApi';
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import TypedDocumentTypesPage from '../tickets/TypedDocumentTypesPage';
import RequestTypesPage from '../tickets/RequestTypesPage';
import TicketsSettingsPage from '../tickets/TicketsSettingsPage';
import DepartmentsPage from '../DepartmentsPage';

import TabPanel from '../../components/TabPanel';

import { validationSchema } from '../../consts/settingsPage';

import { useGetDepartmentsQuery } from '../../api/departmentsApi';

import DepartmentUsersOperators from '../../components/DepartmentUsersOperators';
import SettingsDBOperations from '../../components/SettingsDBOperations';
import consts from '../../consts/consts';

const SettingsPage = () => {
  const { data: settings, isLoading: isSettingsLoading } = useGetSettingsQuery();

  const [updateSettings] = useUpdateSettingsMutation();

  const [currentTab, setCurrentTab] = useState(0);

  const [isDepartmentSelectVisible, setIsDepartmentSelectVisible] = useState(false);

  const { data: departaments = [], isLoading: isDepartmentsLoading } = useGetDepartmentsQuery();

  const selectedDepIdRef = useRef(0);

  const [selectedDepartmentId, setSelectedDepartmentId] = useState(selectedDepIdRef.current);

  const departmentSelectRef = useRef(null);
  const fileInputRef = useRef(null);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({ resolver: yupResolver(validationSchema) });

  useEffect(() => {
    if (!isSettingsLoading && settings) {
      Object.keys(settings).forEach((key) => {
        setValue(key, settings[key]);
      });
    }
  }, [settings, isSettingsLoading, setValue]);

  useEffect(() => {
    if (departaments.length && departaments[0]?.department_id) {
      setSelectedDepartmentId(selectedDepIdRef.current ? selectedDepIdRef.current : departaments[0]?.department_id);
    }
  }, [departaments]);

  async function submitHandler(formData) {
    try {
      const data = new FormData();

      for (const prop in formData) {
        if (prop === 'settings_splash_screen_img' || prop === 'settings_general_app_img') {
          continue;
        }

        data.append(prop, formData[prop]);
      }

      const file = fileInputRef?.current?.files[0];

      data.append('file', file);

      const { success = false, message = '' } = await updateSettings(data).unwrap();

      if (success) {
        toast.success(message);
      } else {
        toast.error(message);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function tabClick_handler(e, val) {
    val != 0 && val != 1 && val != 2 && val != 5 && departmentSelectRef.current.focus();
    setIsDepartmentSelectVisible(val != 0 && val != 1 && val != 2 && val != 5);
    setCurrentTab(val);
  }

  const generalSettings = (
    <form onSubmit={handleSubmit(submitHandler)} className='form w-full bg-transparent shadow-none border-0 px-0 gap-2'>
      <div className='w-full grid grid-cols-1 lg:grid-cols-2 gap-4 md:gap-8'>
        {/* town hall settings */}
        <div className='flex-grow w-full'>
          <h3 className='subTitle self-start'>Date identificare</h3>
          <div className='flexBetween flex-1 gap-2 flex-wrap xs:flex-nowrap w-full'>
            <div className='formControl'>
              <label className='formLabel'>Denumire:</label>
              <input
                type='text'
                className={`formInput ${errors?.settings_hall_name && 'border-[0.1px] border-primary-500'}`}
                {...register('settings_hall_name')}
              />
              <p className='inputErrorMessage'>{errors?.settings_hall_name?.message}</p>
            </div>
            <div className='formControl '>
              <label className='formLabel'>CIF primarie</label>
              <input
                type='text'
                className={`formInput ${errors?.settings_hall_cif && 'border-[0.1px] border-primary-500'}`}
                {...register('settings_hall_cif')}
              />
              <p className='inputErrorMessage '>{errors?.settings_hall_cif?.message}</p>
            </div>
          </div>
          <div className='formControl'>
            <label className='formLabel'>Adresa:</label>
            <input
              type='text'
              className={`formInput ${errors?.settings_hall_address && 'border-[0.1px] border-primary-500'}`}
              {...register('settings_hall_address')}
            />
            <p className='inputErrorMessage'>{errors?.settings_hall_address?.message}</p>
          </div>
          <div className='flexBetween flex-1 gap-2 flex-wrap xs:flex-nowrap w-full'>
            <div className='formControl'>
              <label className='formLabel'>Email:</label>
              <input
                type='text'
                className={`formInput ${errors?.settings_hall_email && 'border-[0.1px] border-primary-500'}`}
                {...register('settings_hall_email')}
              />
              <p className='inputErrorMessage'>{errors?.settings_hall_email?.message}</p>
            </div>
            <div className='formControl'>
              <label className='formLabel'>Telefon:</label>
              <input
                type='text'
                className={`formInput ${errors?.settings_hall_phone && 'border-[0.1px] border-primary-500'}`}
                {...register('settings_hall_phone')}
              />
              <p className='inputErrorMessage'>{errors?.settings_hall_phone?.message}</p>
            </div>
          </div>
          <div className='formControl'>
            <label className='formLabel'>Website:</label>
            <input
              type='text'
              className={`formInput ${errors?.settings_hall_website && 'border-[0.1px] border-primary-500'}`}
              {...register('settings_hall_website')}
            />
            <p className='inputErrorMessage'>{errors?.settings_hall_website?.message}</p>
          </div>
          <div className='formControl'>
            <label className='formLabel'>
              Stema primarie:
              {/* (Extensii acceptate: JPG/JPEG; Dimensiune maxima fisier: 10MB): */}
            </label>
            <input type='file' multiple {...register('file')} ref={fileInputRef} />
            <p className='inputErrorMessage'>{errors?.file?.message}</p>
          </div>
        </div>
        {/* smtp email settings for admin */}
        <div className='flex-grow w-full'>
          <h3 className='subTitle self-start'>Email administrator (raportare)</h3>
          <div className='formControl'>
            <label className='formLabel'>Email administrator</label>
            <input
              type='email'
              className={`formInput ${errors?.settings_admin_email_address && 'border-[0.1px] border-primary-500'}`}
              {...register('settings_admin_email_address')}
            />
            <p className='inputErrorMessage'>{errors?.settings_admin_email_address?.message}</p>
          </div>
          <div className='flexBetween flex-1 gap-2 flex-wrap xs:flex-nowrap w-full'>
            <div className='formControl'>
              <label className='formLabel'>SMTP Server:</label>
              <input
                type='text'
                className={`formInput ${errors?.settings_admin_email_smtp && 'border-[0.1px] border-primary-500'}`}
                {...register('settings_admin_email_smtp')}
              />
              <p className='inputErrorMessage'>{errors?.settings_admin_email_smtp?.message}</p>
            </div>
            <div className='formControl md:w-[50%]'>
              <label className='formLabel'>Port:</label>
              <input
                type='text'
                className={`formInput ${errors?.settings_admin_email_port && 'border-[0.1px] border-primary-500'}`}
                {...register('settings_admin_email_port')}
              />
              <p className='inputErrorMessage '>{errors?.settings_admin_email_port?.message}</p>
            </div>
          </div>
          <div className='formControl md:w-[50%] self-start'>
            <label className='formLabel'>Parola:</label>
            <input
              type='text'
              className={`formInput ${errors?.settings_admin_email_password && 'border-[0.1px] border-primary-500'}`}
              {...register('settings_admin_email_password')}
            />
            <p className='inputErrorMessage'>{errors?.settings_admin_password?.message}</p>
          </div>
        </div>
        {/* smtp email settings for users */}
        {/* <div className='flex-grow w-full'>
          <h3 className='subTitle self-start'>Email No-Reply (notificare utilizatori)</h3>
          <div className='flex-grow w-full'>
            <div className='formControl'>
              <label className='formLabel'>Email No-Reply</label>
              <input
                type='email'
                className={`formInput ${errors?.settings_noreply_email_address && 'border-[0.1px] border-primary-500'}`}
                {...register('settings_noreply_email_address')}
              />
              <p className='inputErrorMessage'>{errors?.settings_noreply_email_address?.message}</p>
            </div>
          </div>
          <div className='flexBetween flex-1 gap-2 flex-wrap xs:flex-nowrap w-full'>
            <div className='formControl'>
              <label className='formLabel'>SMTP Server:</label>
              <input
                type='text'
                className={`formInput ${errors?.settings_noreply_email_smtp && 'border-[0.1px] border-primary-500'}`}
                {...register('settings_noreply_email_smtp')}
              />
              <p className='inputErrorMessage'>{errors?.settings_noreply_email_smtp?.message}</p>
            </div>
            <div className='formControl md:w-[50%]'>
              <label className='formLabel'>Port:</label>
              <input
                type='text'
                className={`formInput ${errors?.settings_noreply_email_port && 'border-[0.1px] border-primary-500'}`}
                {...register('settings_noreply_email_port')}
              />
              <p className='inputErrorMessage'>{errors?.settings_noreply_email_port?.message}</p>
            </div>
          </div>
          <div className='formControl md:w-[50%] self-start mb-5'>
            <label className='formLabel'>Parola:</label>
            <input
              type='text'
              className={`formInput ${errors?.settings_noreply_email_password && 'border-[0.1px] border-primary-500'}`}
              {...register('settings_noreply_email_password')}
            />
            <p className='inputErrorMessage'>{errors?.settings_noreply_email_password?.message}</p>
          </div>
        </div> */}
        {/* paths */}
        {/* <div className='flex-grow w-full'>
          <h3 className='subTitle self-start'>Preluare fisiere</h3>
          <div className='flex-grow w-full'>
            <div className='formControl'>
              <label className='formLabel'>Cale FTP director date taxe si impozite (absoluta)</label>
              <input
                type='text'
                className={`formInput ${errors?.settings_upload_data_path && 'border-[0.1px] border-primary-500'}`}
                {...register('settings_upload_data_path')}
              />
              <p className='inputErrorMessage'>{errors?.settings_upload_data_path?.message}</p>
            </div>
          </div>
        </div> */}
      </div>
      <button className='btn btn-dark self-start w-full xs:max-w-fit' type='submit'>
        Salvare
      </button>
    </form>
  );

  function departamentSelection_handler(e) {
    selectedDepIdRef.current = e.target.value;
    setSelectedDepartmentId(selectedDepIdRef.current);
  }

  useEffect(() => {
    isDepartmentSelectVisible && departmentSelectRef.current.focus();
  }, [isDepartmentSelectVisible]);

  return (
    <div className='flex flex-col gap-4'>
      <h1 className='title'>Setari</h1>
      <Tabs
        value={currentTab}
        onChange={tabClick_handler}
        sx={{
          '& .MuiTabs-indicator': { backgroundColor: 'red !important' },
          '& .Mui-selected': { color: 'red !important' }
        }}
      >
        <Tab label='Setari generale' />
        <Tab label='Departamente' />
        <Tab label='Operatori departamente' />
        <Tab label='Nomenclator' />
        <Tab label='Setari departament' />
        <Tab label='Operatii baza de date' />
      </Tabs>
      <select
        className={`w-100 min-w-full p-3 text-lg rounded font-medium shadow-sm border-[1px] border-secondary-200 uppercase ${
          !isDepartmentSelectVisible && 'hidden'
        }`}
        defaultValue={selectedDepIdRef}
        onChange={departamentSelection_handler}
        ref={departmentSelectRef}
      >
        {departaments?.map((department) => (
          <option value={department.department_id} className='text-lg font-medium' key={department.department_id}>
            {department.department_name}
          </option>
        ))}
      </select>
      <TabPanel value={currentTab} index={0}>
        {generalSettings}
      </TabPanel>
      <TabPanel value={currentTab} index={1}>
        <DepartmentsPage />
      </TabPanel>
      <TabPanel value={currentTab} index={2}>
        <DepartmentUsersOperators departmentId={selectedDepartmentId} />
      </TabPanel>
      <TabPanel value={currentTab} index={3}>
        <div className='flex flex-col gap-4'>
          <RequestTypesPage departmentId={selectedDepartmentId} />
          <TypedDocumentTypesPage departmentId={selectedDepartmentId} />
        </div>
      </TabPanel>
      <TabPanel value={currentTab} index={4}>
        <TicketsSettingsPage departmentId={selectedDepartmentId} />
      </TabPanel>
      <TabPanel value={currentTab} index={5}>
        <SettingsDBOperations />
      </TabPanel>
    </div>
  );
};

export default SettingsPage;
