import useCurrentDepartment from '../../hooks/useCurrentDepartment';
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_RO } from 'material-react-table/locales/ro';
import Modal from '@mui/material/Modal';
import { useMemo, useRef, useState } from 'react';
import { AiOutlineClose, AiFillEdit, AiFillEye, AiFillFilePdf, AiOutlinePlus } from 'react-icons/ai';
import { toast } from 'react-toastify';
import { downloadFile, formatDate, getUsername } from '../../utils/fn';
import {
  useGetTicketsByUserIdQuery,
  useAddTicketMutation,
  useRemoveTicketMutation,
  useUpdateTicketMutation,
  useGetTicketsByUserIdAndDepartmentIdQuery
} from '../../api/ticketsApi';
import { FaTrash } from 'react-icons/fa';
import { Link, useOutletContext } from 'react-router-dom';
import { useGetNormalUsersQuery } from '../../api/usersApi';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { useGetRequestTypesByDepartmentQuery } from '../../api/typesApi';
import { useNavigate } from 'react-router-dom';
import consts from '../../consts/consts';

const TicketsPage = () => {
  const navigate = useNavigate();

  const { connectedUser, isUserLoading } = useOutletContext();

  const [isAddUpdateModalOpen, setIsAddUpdateModalOpen] = useState(false);

  const [addTicket] = useAddTicketMutation();

  const [updateTicket] = useUpdateTicketMutation();

  const [removeTicket] = useRemoveTicketMutation();

  const [selectedUserId, setSelectedUserId] = useState(
    !!+connectedUser.user_is_super_admin || !!+connectedUser.user_is_admin ? 0 : connectedUser.user_id
  );

  const { currentDepartment, isLoading: isDepartmentLoading } = useCurrentDepartment();

  // const { data: tickets = [], isLoading: isTicketsLoading } = useGetTicketsByUserIdQuery(selectedUserId);
  const { data: tickets = [], isLoading: isTicketsLoading } = useGetTicketsByUserIdAndDepartmentIdQuery({
    userId: selectedUserId,
    departmentId: currentDepartment?.department_id || 0
  });

  const { data: requestTypes = [], isLoading: isRequestTypesLoading } = useGetRequestTypesByDepartmentQuery(
    currentDepartment?.department_id
  );

  const { data: users, isLoading: isUsersLoading } = useGetNormalUsersQuery();

  const [datesInterval, setDatesInterval] = useState({
    fromDate: `${new Date().getFullYear()}-01-01`,
    toDate: `${new Date().getFullYear()}-12-31`
  });

  const formRef = useRef(null);
  const inputUserIdRef = useRef(null);

  const [operatedRow, setOperatedRow] = useState(null);

  const [operatedRowId, setOperatedRowId] = useState(0);

  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);

  const [isActive, setIsActive] = useState('all');

  const schema = yup.object().shape({
    ticket_name: yup.string().required('Camp obligatoriu'),
    ...(!!+connectedUser.user_is_admin || !!+connectedUser.user_is_super_admin
      ? { ticket_user_id: yup.string().required('Camp obligatoriu') }
      : {}),
    ticket_register_number: yup.string(),
    ticket_request_type_id: yup.string().required('Camp obligatoriu'),
    ...(!operatedRow ? { ticket_content: yup.string().required('Camp obligatoriu') } : {})
  });

  const {
    register,
    trigger,
    resetField,
    setValue,
    setError,
    watch,
    formState: { errors }
  } = useForm({ resolver: yupResolver(schema) });

  const formFilesRef = useRef(null);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'ticket_number',
        header: 'Nr.',
        size: 60
      },
      {
        accessorKey: 'ticket_register_number',
        header: 'Registru',
        size: 100
      },
      {
        accessorKey: 'ticket_name',
        header: 'Denumire',
        size: 200
      },
      {
        accessorKey: 'request_type_name',
        Cell: ({ cell }) => <strong className='uppercase'>{cell.getValue()}</strong>,
        header: 'Solicitare',
        size: 100
      },
      {
        accessorKey: 'user_firstname',
        accessorFn: (row) => `${row.user_firstname} ${row.user_lastname}`,
        header: 'Utilizator',
        size: 150
      },
      {
        accessorFn: (row) => row.ticket_is_active,
        Cell: ({ cell }) => (
          <span className={`uppercase ${+cell.getValue() ? 'text-green-500' : 'text-primary-500'}`}>
            {+cell.getValue() ? 'Deschis' : 'Inchis'}
          </span>
        ),
        header: 'Stare',
        size: 100
      },
      {
        accessorKey: 'ticket_created_at',
        accessorFn: (row) => formatDate(row.ticket_created_at),
        header: 'Data',
        size: 100
      }
    ],
    []
  );

  const filteredTickets = useMemo(
    () =>
      tickets?.filter((x) => {
        if (isActive == 'all') {
          return x.ticket_created_at >= datesInterval.fromDate && x.ticket_created_at <= datesInterval.toDate;
        } else {
          return (
            x.ticket_created_at >= datesInterval.fromDate &&
            x.ticket_created_at <= datesInterval.toDate &&
            x.ticket_is_active == isActive
          );
        }
      }),
    [tickets, datesInterval, isActive]
  );

  function resetModalFields() {
    resetField('ticket_name');
    resetField('ticket_content');
    resetField('ticket_user_id');
    resetField('ticket_request_type_id');
    resetField('ticket_register_number');
    resetField('files');
  }

  function closeModals() {
    setIsRemoveModalOpen(false);
    setIsAddUpdateModalOpen(false);
    setOperatedRow(null);
    resetModalFields();
  }

  async function addUpdateTicket_handler(e, data) {
    e.preventDefault();

    try {
      const isValid = await trigger();

      if (!isValid) {
        return;
      }

      let res;
      const formData = new FormData(formRef.current);

      formData.append('ticket_department_id', currentDepartment.department_id);

      if (data) {
        res = await updateTicket({ data: formData, id: data.ticket_id }).unwrap();
      } else {
        const files = formFilesRef?.current?.files;

        Array.from(files)?.forEach((file) => {
          formData.append('files[]', file);
        });

        if ( !Boolean(+connectedUser?.user_is_admin) && !Boolean(+connectedUser?.user_is_super_admin) ) {
          formData.append('ticket_user_id', connectedUser?.user_id);
        } 

        res = await addTicket(formData).unwrap();
      }

      const { success = false, message = '', id = 0 } = res;

      if (success) {
        if (data) {
          toast.success(message);
        } else {
          localStorage.setItem(
            consts['LOCAL_STORAGE_KEY_PAGE_MESSAGE'],
            JSON.stringify({
              message
            })
          );
          navigate(`/tickets/${currentDepartment.department_id}/${id}`);
        }
      } else {
        toast.error(message);
      }

      closeModals();
    } catch (error) {
      console.error(error);
    } finally {
    }
  }

  async function remove_handler(operatedRow) {
    try {
      if (!operatedRow) return;

      const res = await removeTicket(operatedRow['ticket_id']).unwrap();
      const { success = false, message = '' } = res;

      success && toast.success(message);
      !success && toast.error(message);

      closeModals();
    } catch (error) {
      console.error(error);
    }
  }

  async function createTicketsReport_handler(e) {
    try {
      e.preventDefault();

      const res = await fetch(
        `${window.API_URL}/reports/tickets_report.php?userId=${selectedUserId}&fromDate=${datesInterval.fromDate}&toDate=${datesInterval.toDate}&departmentId=${currentDepartment?.department_id}&isActive=${isActive}`,
        {
          credentials: 'include'
        }
      );

      const { fileName = '', filePath = '', message = '' } = await res.json();

      message.length && toast.success(message);

      filePath.length && downloadFile(fileName, filePath, true);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    !isDepartmentLoading &&
    currentDepartment &&
    !isUserLoading &&
    connectedUser && (
      <div className='flex flex-col gap-4'>
        {/* modal for removing the department */}
        <Modal open={isRemoveModalOpen} onClose={closeModals}>
          <div className='modalContainer md:max-w-[600px]'>
            <div className='modalHeader'>
              <h1 className='subTitle text-white'>Eliminare solicitare</h1>
              <button className='text-gray-100 hover:text-white text-xl' onClick={closeModals}>
                <AiOutlineClose />
              </button>
            </div>
            <div className='modalBody'>
              <p>
                Solicitarea <strong className='uppercase'>{operatedRow?.ticket_name}</strong> va fi eliminata, sunteti
                sigur?
              </p>
            </div>
            <div className='modalFooter'>
              <button className='btn btn-primary' onClick={() => remove_handler(operatedRow)}>
                Da
              </button>
              <button className='btn btn-dark' onClick={closeModals}>
                Anulare
              </button>
            </div>
          </div>
        </Modal>
        {/* add update modal */}
        <Modal
          open={isAddUpdateModalOpen}
          onClose={(e) => {
            closeModals();
          }}
        >
          <div className='modalContainer md:max-w-[600px]'>
            <div className='modalHeader'>
              <h1 className='subTitle text-white'>{operatedRow ? 'Modificare solicitare' : 'Adaugare solicitare'}</h1>
              <button
                className='text-gray-100 hover:text-white text-xl'
                onClick={(e) => {
                  closeModals();
                }}
              >
                <AiOutlineClose />
              </button>
            </div>
            <div className='modalBody'>
              <form ref={formRef} className='w-full flex flex-col'>
                <div className='formControl'>
                  <label className='formLabel'>Denumire:</label>
                  <input
                    type='text'
                    className={`formInput ${errors?.ticket_name && 'border-[0.1px] border-primary-500'}`}
                    {...register('ticket_name')}
                  />
                  <p className='inputErrorMessage'>{errors?.ticket_name?.message}</p>
                </div>
                {!!+connectedUser.user_is_super_admin || !!+connectedUser.user_is_admin ? (
                  <div className='formControl'>
                    <label className='formLabel'>Utilizatori:</label>
                    <select
                      className={`formInput ${errors?.ticket_user_id && 'border-[0.1px] border-primary-500'}`}
                      name=''
                      id=''
                      defaultValue={0}
                      ref={inputUserIdRef}
                      {...register('ticket_user_id')}
                    >
                      <option value=''></option>
                      {users?.map((x) => (
                        <option value={x.user_id} key={x.user_id}>
                          {getUsername(x)}
                        </option>
                      ))}
                    </select>
                    <p className='inputErrorMessage'>{errors?.ticket_user_id?.message}</p>
                  </div>
                ) : (
                  ''
                )}
                <div className='formControl'>
                  <label className='formLabel'>Tip solicitare:</label>
                  <select
                    className={`formInput ${errors?.ticket_request_type_id && 'border-[0.1px] border-primary-500'}`}
                    name=''
                    id=''
                    {...register('ticket_request_type_id')}
                    defaultValue={0}
                  >
                    <option value=''></option>
                    {requestTypes?.map((x) => (
                      <option value={x.request_type_id} key={x.request_type_id}>
                        {x.request_type_name}
                      </option>
                    ))}
                  </select>
                  <p className='inputErrorMessage'>{errors?.ticket_request_type_id?.message}</p>
                </div>
                {operatedRow ? (
                  <div className='formControl'>
                    <label className='formLabel'>Nr. registru hartie:</label>
                    <input
                      type='text'
                      className={`formInput ${errors?.ticket_register_number && 'border-[0.1px] border-primary-500'}`}
                      {...register('ticket_register_number')}
                    />
                    <p className='inputErrorMessage'>{errors?.ticket_register_number?.message}</p>
                  </div>
                ) : (
                  ''
                )}
                {!operatedRow ? (
                  <>
                    <div className='formControl'>
                      <label>Continut:</label>
                      <textarea
                        className={`formInput ${errors?.ticket_content && 'border-[0.1px] border-primary-500'}`}
                        rows={3}
                        {...register('ticket_content')}
                      ></textarea>
                      <p className='inputErrorMessage'>{errors?.ticket_content?.message}</p>
                    </div>
                    <div className='flex flex-col'>
                      <label htmlFor='files'>Atasamente ({consts['FILES_INPUT_TEXT_INFO']}):</label>
                      <input type='file' multiple id='files' {...register('files')} ref={formFilesRef} />
                    </div>
                  </>
                ) : (
                  ''
                )}
              </form>
            </div>
            <div className='modalFooter'>
              <button
                onClick={(e) => addUpdateTicket_handler(e, operatedRow)}
                type='submit'
                className='btn btn-primary self-start w-full xs:max-w-fit'
              >
                {operatedRow ? 'Modificare' : 'Adaugare'}
              </button>
              <button
                className='btn btn-dark'
                onClick={() => {
                  closeModals();
                }}
              >
                Anulare
              </button>
            </div>
          </div>
        </Modal>
        <h1 className='title'>{currentDepartment?.department_name} / Solicitari</h1>
        {(!!+connectedUser?.user_is_super_admin || !!+connectedUser?.user_is_admin) && (
          <div className='flex gap-4 justify-end'>
            <button className='btn btn-dark gap-2' onClick={(e) => createTicketsReport_handler(e)}>
              <AiFillFilePdf /> Raport solicitari
            </button>
          </div>
        )}
        <div className='w-full'>
          <MaterialReactTable
            columns={columns}
            data={filteredTickets ?? []}
            getRowId={(row) => row.typed_document_type_id}
            state={{ isTicketsLoading }}
            enableStickyHeader
            enableStickyFooter
            enableColumnResizing
            localization={MRT_Localization_RO}
            initialState={{
              showGlobalFilter: true,
              density: 'compact',
              columnPinning: { right: ['actions'] }
            }}
            muiSearchTextFieldProps={{
              sx: {
                padding: '0',
                display: 'flex',
                alignSelf: 'flex-end',
                '& > *': {
                  height: '37px'
                }
              }
              // variant: 'outlined'
            }}
            muiTopToolbarProps={{
              sx: {
                alignItems: 'flex-end',
                '& > *': {
                  display: 'flex',
                  alignItems: 'flex-end'
                }
              }
            }}
            // renderTopToolbarCustomActions={({ table }) => null}
            enablePinning
            // defaultColumn={{
            //   maxSize: 400,
            //   minSize: 80,
            //   size: 100 //default size is usually 180
            // }}
            enableDensityToggle={false}
            layoutMode='grid'
            enableRowActions
            displayColumnDefOptions={{
              'mrt-row-actions': {
                header: '', //change header text
                minSize: 30,
                maxSize: 300
              }
            }}
            renderRowActions={({ row: { original: data } }, index) => (
              <div className='flex gap-1'>
                <Link
                  to={`/tickets/${currentDepartment.department_id}/${data.ticket_id}`}
                  className='btn btn-outline btn-xm hover:scale-[0.99] p-1'
                >
                  <AiFillEye />
                </Link>
                {(!!+connectedUser.user_is_admin || !!+connectedUser.user_is_super_admin) && (
                  <button
                    className='btn btn-outline btn-xm hover:scale-[0.99] p-1'
                    onClick={(e) => {
                      setOperatedRow(data);
                      setOperatedRowId(data.ticket_id);
                      setIsAddUpdateModalOpen(true);
                      setValue('ticket_name', data.ticket_name);
                      setValue('ticket_user_id', data.ticket_user_id);
                      setValue('ticket_request_type_id', data.ticket_request_type_id);
                      setValue('ticket_register_number', data.ticket_register_number);
                    }}
                  >
                    <AiFillEdit />
                  </button>
                )}
                {!Boolean(+data.ticket_is_active) &&
                  (!!+connectedUser.user_is_admin || !!+connectedUser.user_is_super_admin) && (
                    <button
                      className='btn btn-outline btn-xm hover:scale-[0.99] p-1'
                      onClick={(e) => {
                        setOperatedRow(data);
                        setOperatedRowId(data.ticket_id);
                        setIsRemoveModalOpen(true);
                      }}
                    >
                      <FaTrash />
                    </button>
                  )}
              </div>
            )}
            muiTableBodyRowProps={({ row: { original: data } }) => ({
              sx: {
                ...(data.ticket_id === operatedRowId && { background: '#fbd6d3' })
              },
              hover: false
            })}
            positionActionsColumn='last'
            muiTableBodyCellProps={{
              sx: {
                whiteSpace: 'pre-line'
              }
            }}
            muiTableHeadCellProps={{
              sx: {
                // background: "#475569",
                color: 'black',
                fontWeight: 'bold'
              }
            }}
            muiTableContainerProps={{
              sx: {
                maxHeight: '500px'
              }
            }}
            muiTablePaperProps={{
              sx: {
                borderRadius: '0',
                border: '0.1px solid #e0e0e0',
                boxShadow: 'none'
              }
            }}
            muiTableProps={{
              sx: {
                boxShadow: 'none'
              }
            }}
            muiTableHeadRowProps={{
              sx: {
                boxShadow: 'none'
              }
            }}
            renderTopToolbarCustomActions={({ table }) => (
              <div className='flex items-end justify-start gap-1 flex-wrap'>
                {!Boolean(+connectedUser?.user_is_admin) && !Boolean(+connectedUser?.user_is_super_admin) ? (
                  <button className='btn btn-dark' onClick={(e) => setIsAddUpdateModalOpen(true)}>
                    Adauga solicitare
                  </button>
                ) : (
                  <button className='btn btn-dark' onClick={(e) => setIsAddUpdateModalOpen(true)}>
                    Adauga solicitare
                  </button>
                )}
                <div className='basis-0'>
                  <label htmlFor='fromDate' className='text-sm'>
                    De la:
                  </label>
                  <input
                    type='date'
                    className='formInput'
                    name='fromDate'
                    id='fromDate'
                    value={datesInterval.fromDate}
                    onChange={(e) =>
                      setDatesInterval({
                        ...datesInterval,
                        [e.target.name]: e.target.value
                      })
                    }
                  />
                </div>
                <div className='basis-0'>
                  <label htmlFor='toDate' className='text-sm'>
                    Pana la:
                  </label>
                  <input
                    type='date'
                    className='formInput'
                    id='toDate'
                    name='toDate'
                    value={datesInterval.toDate}
                    onChange={(e) =>
                      setDatesInterval({
                        ...datesInterval,
                        [e.target.name]: e.target.value
                      })
                    }
                  />
                </div>
                {Boolean(+connectedUser.user_is_admin) || !!+connectedUser.user_is_super_admin ? (
                  <div className='max-w-[200px]'>
                    <label htmlFor='users' className='text-sm'>
                      Utilizator:
                    </label>
                    <select
                      onChange={(e) => setSelectedUserId(e.target.value)}
                      id='users'
                      className='formInput py-[0.409rem]'
                      defaultValue={0}
                    >
                      <option value='0'>Toti utilizatorii</option>
                      {!isUsersLoading &&
                        users?.map((user) => (
                          <option value={user.user_id} key={user.user_id}>
                            {getUsername(user)}
                          </option>
                        ))}
                    </select>
                  </div>
                ) : null}
                <div className='max-w-[200px]'>
                  <label htmlFor='status' className='text-sm'>
                    Status:
                  </label>
                  <select
                    onChange={(e) => setIsActive(e.target.value)}
                    id='status'
                    className='formInput py-[0.409rem]'
                    defaultValue={0}
                  >
                    <option value='all' selected={isActive == 'all'}>
                      Toate
                    </option>
                    <option value='1' selected={isActive == '1'}>
                      Deschise
                    </option>
                    <option value='0' selected={isActive == '0'}>
                      Inchise
                    </option>
                  </select>
                </div>
              </div>
            )}
          />
        </div>
      </div>
    )
  );
};

export default TicketsPage;
